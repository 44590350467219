<script>
//import Multiselect from "vue-multiselect";
//import {required,} from "vuelidate/lib/validators";
import { Upload, Dialog} from 'element-ui'

import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import 'element-ui/lib/theme-chalk/index.css'

import "vue-multiselect/dist/vue-multiselect.min.css";
import ArregloService from "@/services/ArregloService";
import CategoriaService from "@/services/CategoriaService";
import TipoService from "@/services/TipoService";
const categoriaService = new CategoriaService();
const tipoService = new TipoService();

const itemService = new ArregloService();

import HelperJS from "@/functions/general";
const helper = new HelperJS();

import InfoModel from "@/classes/arreglo";
import ImageModel from "@/classes/arreglo_image";
const infoModel = new InfoModel();
const imageModel = new ImageModel();

/**
 * Add-product component
 */
const nameSeccion = 'Arreglo';
//const namePlural = nameSeccion+'s';
const routeParentName = 'arreglos';

export default {
  page: {
    title: "Arreglo",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
            [Upload.name]: Upload,
            [Dialog.name]: Dialog,
  },
  data() {
    return {
      title: "Arreglo",
      actionCreate: true,
      dialogImageUrl: '',
      listImgs:[],
      imgsBash:[],
      dialogVisible: null,
      info_page:{
        routeParentName: null,
        routeBack: null,
        nameSeccion: null,
      },
      categorias: [],
      tipos: [],
      listImages:[],
      objectData:{},
      items: [
        {
          text: null,
        },
        {
          text: null,
          active: true,
        },
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 200,
        maxFilesize: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      },
      value1: null,
      options: [
        "High Quality",
        "Leather",
        "Notifications",
        "Sizes",
        "Different Color",
      ],
    };
  },
  middleware: "authentication",
  
  created(){

    this.initPage();
  },
  
  methods: {
    async initPage(){
        await this.aditionalServices();
        this.info_page.routeParentName = routeParentName;
        this.info_page.nameSeccion = nameSeccion;
        this.info_page.routeBack = '/'+routeParentName;
        let text_page = 'Agregar '
        
        this.objectData = JSON.parse(JSON.stringify(infoModel.fillable));
        if(this.$route.name==='ArregloEdit'){
          
          this.actionCreate = false;
          text_page = 'Editar';

          itemService.getById(this.$route.params.id).then(async response=>{  
            let data = response.data.object_data;
            let objectData =   JSON.parse(JSON.stringify(new InfoModel(data)));
            helper.compareArrayAdditional(this.categorias, objectData.categories)
            helper.compareArrayAdditional(this.tipos, objectData.types)

            let images = objectData.image;
            images.map(item=> item.onServer = true);
            this.listImgs = images;

            this.objectData = objectData;
          })

        }
        
        this.items[0].text= nameSeccion        
        this.items[1].text= text_page+' '+nameSeccion;
      },

      async aditionalServices(){
        let response = await categoriaService.getList();
        response =  response.data.object_data;
        response.map(item=> item.status = false);
        this.categorias = response;
       
        response = await tipoService.getList();
        response =  response.data.object_data;
        response.map(item=> item.status = false);
        this.tipos = response;
      },

      async updateForm(){
        let formData = JSON.parse(JSON.stringify(this.objectData));
        formData.categories = helper.getActivesArray(this.categorias);
        formData.types = helper.getActivesArray(this.tipos);
        let response_id = null;
        let responseData = null;
        delete formData.fillable;
        if(this.actionCreate){
          delete formData.id;
          responseData = await itemService.create(formData)
        }else{
           responseData = await itemService.update(formData)
        }
        
        responseData = responseData.data.object_data;
        response_id= responseData.id; 
        let newItems = this.listImgs.filter(item=> item.onServer === false)

        let removeItems = this.imgsBash;
        helper.asyncForEach(removeItems, item=>{
          itemService.deleteImage(item._id);
        })
        helper.asyncForEach(newItems, async item=>{
          await itemService.createImage(response_id, item.url);
        
        })

        this.successForm(this.info_page.routeBack);
        this.$v.$touch();

      },

      slugify(stringValue){

        return helper.convertToSlug(stringValue);
      },
      successForm(route){
        Swal.fire("¡Operación exitosa!", "Información guardada con éxito", "success").then(() => {
        this.$router.push({
              path: route,
            });
        });
    },
    cancelForm(){
      this.$router.push({
        path:'/'+routeParentName,
      });
    },
      
      validateForm(){

        this.updateForm();
      },
     routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },  
    showSuccess(file) {
      console.log(file);
    },
            addAttachment(file){
                const image = file.file;
                const reader = new FileReader();
                reader.readAsDataURL(image);
                
                reader.onload = e =>{
                  let  data = JSON.parse(JSON.stringify(imageModel.fillable))
                 
                    data.url= e.target.result;
                    data.onServer = false;
                  
                    this.listImgs.push(data);
                };
            },
            handleExceed(file, fileList) {
               //console.log('handleExceed', fileList);
                if (fileList.length === 4) {

                  Swal.fire({
                        type: 'warning',
                        icon: "warning",
                        text: 'Se ha excedido el número imagenes',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    })

                    return false;
                }
            },
            handleRemove(file) {
                let idxKey = this.listImgs.findIndex(item => item.uid===file.uid)


               //console.log('RM', idxKey, file, fileList, this.listImgs);
                if(file.onServer){
                    this.imgsBash.push(file._id);
                }
                if(idxKey>=0){
                    this.listImgs.splice(idxKey, 1);
                }
            },
            handlePictureCardPreview(file) {
              
              console.log('file');
                console.log(file);
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            submitUpload() {
               //console.log( this.$refs.upload);
            },
            onBeforeUpload(file)
            {
                const isIMAGE = file.type === 'image/jpeg'||'image/gif'||'image/png';
                const isLt1M = file.size / 1024 / 1024 < 2;

                if (!isIMAGE) {
                  Swal.fire({
                        type: 'warning',
                        icon: "warning",
                        text: 'El archivo de carga solo puede estar en formato de imagen',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    })
                    return isLt1M;
                }
                if (!isLt1M) {
                  Swal.fire({
                        type: 'warning',
                        icon: "warning",
                        text: 'El tamaño de la imagen no puede exceder 2 MB',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    })
                    return isLt1M;
                }
            },
            

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark"
              data-toggle="collapse"
              aria-expanded="true"
              aria-controls="addproduct-billinginfo-collapse"
              v-b-toggle.accordion-1
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Información general</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completa todos los datos
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                <form>
                 
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="productname">Nombre</label>
                        <input
                          id="productname"
                          name="productname"
                          v-model="objectData.name"
                      
                          @input=" objectData.slug = slugify(objectData.name)"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="manufacturername">Slug</label>
                        <input
                          id="manufacturername"
                          name="manufacturername"
                          type="text"
                          class="form-control"
                          v-model="objectData.slug"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="price">Precio</label>
                        <input
                          id="price"
                          name="price"
                          type="text"
                          class="form-control"
                        v-model="objectData.price"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="price">Precio  promoción</label>
                        <input
                          id="price"
                          name="price"
                          type="text"
                          class="form-control"
                          v-model="objectData.price_off"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3 mb-0">
                        <label for="productdesc">Preview</label>
                        <textarea
                          class="form-control"
                          id="productdesc"
                          rows="4"
                          v-model="objectData.preview"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-3">
                        <label class="control-label">Incluye</label>
                        <textarea
                          class="form-control"
                          id="productdesc"
                          rows="4"
                          v-model="objectData.include"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-3 mb-0">
                        <label for="productdesc">Descripción</label>
                        <textarea
                          class="form-control"
                          id="productdesc"
                          rows="4"
                          v-model="objectData.description"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mt-3 mb-0">
                        <label >Categorias</label>
                      </div>
                    </div>
                       <div class="col-2"  v-for="element, idx in categorias" :key="idx">
                         <div class="form-group">
                           <div class="form-check">
                             <input
                               type="checkbox"
                               class="form-check-input"
                               :id="'formcat-customCheck-'+element.id"
                               :value="element.id"
                               v-model="element.status"
                             />
                             <label  class="form-check-label" :for="'formcat-customCheck-'+element.id">{{element.name}}</label
                             >
                           </div>
                         </div>
                       </div>
                     </div>
                     
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mt-3 mb-0">
                        <label >Tipo de flor</label>
                      </div>
                    </div>
                       <div class="col-2"  v-for="element, idx in tipos" :key="idx">
                         <div class="form-group">
                           <div class="form-check">
                             <input
                               type="checkbox"
                               class="form-check-input"
                               :id="'formflor-customCheck-'+element.id"
                               :value="element.id"
                               v-model="element.status"
                             />
                             <label  class="form-check-label" :for="'formflor-customCheck-'+element.id">{{element.name}}</label
                             >
                           </div>
                         </div>
                       </div>
                     </div>
                </form>
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="addproduct-img-collapse"
              v-b-toggle.accordion-2
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        02
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Imagenes del arreglo</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completa todos los datos
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              data-parent="#addproduct-accordion"
            >
              <div class="p-4 border-top">
                <el-upload
                action=""
                                    list-type="picture-card"
                                    :on-preview="handlePictureCardPreview"
                                    :on-remove="handleRemove"
                                    accept="image/jpeg,image/gif,image/png"
                                    :before-upload="onBeforeUpload"
                                    :http-request="addAttachment"
                                    :limit="4"
                                    :on-exceed="handleExceed"
                                    :file-list="listImgs"
                                    :auto-upload="true"
                            >
                                <i class="el-icon-plus"></i>
                            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
                            
              </div>
            </b-collapse>
          </div>

          <div class="card">
            <a
              href="javascript: void(0);"
              class="text-dark collapsed"
              v-b-toggle.accordion-3
            >
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        03
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Meta Data SEO</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completa todos los datos
                    </p>
                  </div>
                  <i
                    class="mdi mdi-chevron-up accor-down-icon font-size-24"
                  ></i>
                </div>
              </div>
            </a>

            <b-collapse
              id="accordion-3"
              accordion="my-accordion"
              data-parent="#addproduct-accordion"
            >
              <div class="p-4 border-top">
                <form>
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="mb-3">
                        <label for="metatitle">Meta título</label>
                        <input
                          id="metatitle"
                          name="metatitle"
                          type="text"
                          class="form-control"
                          v-model="objectData.seo_title"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="mb-3 mb-0">
                    <label for="metadescription">Meta Descripción</label>
                    <textarea
                      class="form-control"
                      id="metadescription"
                      v-model="objectData.seo_description"
                      rows="4"
                    ></textarea>
                  </div>
                </form>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a  class="btn btn-danger"  @click.prevent="cancelForm()">
          <i class="uil uil-times me-1"></i> Cancelar
        </a>
        <a href="#" class="btn btn-success ms-1"  @click="validateForm()">
          <i class="uil uil-file-alt me-1" ></i> Guardar cambios
        </a>
      </div>
      <!-- end col -->
    </div>
  </Layout>
</template>
